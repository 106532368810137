.tableContainer {
    overflow: auto;
    border: 1px solid #e1e6f2;
    width: 100%;
}
.dataTable {
    margin-bottom: 0;
    
}
.dataTable thead tr {
    position: sticky;
    background-color: #e1e6f2;
    top: 0;
    z-index: 1;
}
.dataTable th {
    /* height: 60px; */
    align-items: center;
}
.dataTable td {
    height: 70px;
    align-items: center;
}
.dataTable tbody td {
    padding: 0px;
    padding-top: 10px;
}
.dataTable th div {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
}
.tableBodyCellContainer {
    background-color: white;
    height: 100%;
    padding: .5rem .5rem;
    display: flex;
    align-items: center;
}
.tableBodyCellContainerRed {
    background-color: rgb(224, 6, 6);
    height: 100%;
    padding: .5rem .5rem;
    display: flex;
    align-items: center;
}
.dataTable .table-expander{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
}